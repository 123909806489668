html {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.navbar-nav {
  gap: 1rem;
}

.app-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.select-user {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  > li {
    flex: 200px;
    display: flex;
  }
}

.select-user-button {
  border: 1px solid #ccc;
  background: #dedede;
  display: flex;
  padding: 1rem;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.select-user-icon {
  height: 64px;
  width: 64px;
}

.select-user-name {
  font-weight: bold;
}

.select-user-role {
  font-size: 0.8rem;
}

.app-content {
  flex: 1;
}

footer {
  padding: 1rem 0;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.error {
  color: red;
  font-weight: bold;
}

html, body, #app {
  height: 100%;
  width: 100%;
}

#app {
  display: flex;
  flex-direction: column;
}

$tile-height: 70px;
$tile-width: 70px;
$tile-gap: 10px;

.tiles {
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  grid-template-rows: repeat(auto-fit, 70px);
  grid-gap: 10px;
  overflow: visible;

  .tile {
    display: flex;
    position: relative;

    @for $i from 1 through 8 {
      &.cols-#{$i} {
        grid-column: span $i;
        width: $i * $tile-width + ($i - 1) * $tile-gap;
      }

      &.rows-#{$i} {
        grid-row: span $i;
        height: $i * $tile-width + ($i - 1) * $tile-gap;
      }
    }

    &:hover {
      outline: rgba(29, 29, 29, 0.1) solid 4px;
    }

    .teaser {
      font-size: 1.2em;
      padding: 1em;
      background: #f8f9fa;
      flex: 1;
    }
  }
}

.notifications {
  position: fixed;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  right: 2em;
  bottom: 2em;
}

.notification-toast {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding: 0.8em;
  background-color: #fff;
  max-width: 500px;
  position: relative;
  box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.2);

  &+& {
    margin-top: 1em;
  }

  &.success:before {
    background-color: #2BDE3F;
  }

  &.error:before {
    background-color: #e7431a;
  }

  &.info:before {
    background-color: #1D72F3;
  }

  &.warning:before {
    background-color: #FFC007;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  .notification-toast-details {
    flex: 1;

    .notification-toast-title {
      color: #3e3e3e;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 8px;
    }

    .notification-toast-description {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 0;
      color: #878787;
    }
  }

  .notification-toast-close {
    width: 14px;
    cursor: pointer;
    height: 14px;
    fill: #878787;
    margin-left: 1.2em;
    background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 15.642 15.642"><path fill-rule="evenodd" d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z"></path></svg>') no-repeat center center;
  }
}

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
